package com.fireavert.properties.data

import com.fireavert.common.Try
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.gateways.models.request.PropertyGatewayChangeRequest
import com.fireavert.properties.api.PropertyDataSource
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.properties.logic.models.request.*
import com.fireavert.properties.logic.models.response.*
import com.fireavert.properties.logic.property_admin.DeletePropertyAdminResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ClientPropertyDataSource(private val httpClient: HttpClient, private val baseUrl: String) :
    PropertyDataSource {
    override suspend fun createProperty(
        token: String,
        request: CreatePropertyRequest
    ): Try<PropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getProperties(token: String): Try<GetPropertiesResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getPropertiesWithManagementCompany(token: String): Try<GetPropertiesWithManagementCompanyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/propertiesAndManagementCompanies")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getPropertyById(token: String, id: Int): Try<PropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$id")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun modifyProperty(
        token: String,
        propertyId: Int,
        request: ModifyPropertyRequest
    ): Try<PropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.put {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun deleteProperty(
        token: String,
        propertyId: Int
    ): Try<DeletePropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.delete {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getPropertyAdmins(
        token: String,
        propertyId: Int
    ): Try<GetPropertyAdminsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/propertyAdmins")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun deletePropertyAdmin(
        token: String,
        propertyId: Int,
        propertyAdminId: Int
    ): Try<DeletePropertyAdminResponse> {
        return Try.fromCoCallable {
            val response = httpClient.delete {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/propertyAdmins/$propertyAdminId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun createPropertyAdmin(
        token: String,
        propertyId: Int,
        propertyAdminRequest: CreatePropertyAdminRequest
    ): Try<CreatePropertyAdminResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/propertyAdmins")
                contentType(ContentType.Application.Json)
                setBody(propertyAdminRequest)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun modifyPropertyAdmin(
        token: String,
        propertyId: Int,
        request: ModifyPropertyAdminRequest
    ): Try<ModifyPropertyAdminResponse> {
        return Try.fromCoCallable {
            val response = httpClient.put {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/propertyAdmins/${request.userId}")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getUnlinkedPropertyAdmins(
        token: String,
        propertyId: Int
    ): Try<GetPropertyAdminsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/unlinkedPropertyAdmins")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun linkPropertyAdmin(
        token: String,
        propertyId: Int,
        request: LinkPropertyAdminRequest
    ): Try<LinkPropertyAdminResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/propertyAdminLinks")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun resendPropertyAdminInvite(
        token: String,
        propertyAdminId: String
    ): Try<ResendPropertyAdminInviteResponse> {
        val request = ResendPropertyAdminInviteRequest(propertyAdminId)
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/resendPropertyAdminInvite")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getFireClaims(token: String, propertyId: Int): Try<List<FireClaimData>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/fireClaims")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun modifyFireClaims(
        token: String,
        fireClaimRequest: PropertyFireClaimRequest
    ): Try<FireClaimDataSaveResponse> {
        return Try.fromCoCallable {
            val response = httpClient.put {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/fireClaims")
                contentType(ContentType.Application.Json)
                setBody(fireClaimRequest)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getPropertyGateways(token: String, propertyId: Int): Try<List<GatewayModel>> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/$propertyId")
            }
            if (request.status.isSuccess()) request.body()
            else throw ClientRequestException(request, "")
        }
    }

    override suspend fun modifyGatewayChanges(token: String, request: PropertyGatewayChangeRequest): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun turnOnTestMode(token: String, request: PropertyModeChangeRequest): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/setMode")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun archiveProperty(
        token: String,
        request: ArchivePropertyRequest
    ): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/archive")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getSensorTypesForProperty(
        token: String,
        propertyId: Int?
    ): Try<PropertySensorTypeResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/$propertyId/sensorTypes")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getManagementCompanies(token: String): Try<GetManagementCompaniesResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/managementCompanies")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}