package com.fireavert.components.common.shared

import com.fireavert.styles.StandardizedStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

external interface PageHeaderNavigationProps : Props {
    var linkCalls: Map<String, HeaderLink>
}

val PageHeaderNavigation = FC<PageHeaderNavigationProps> { props ->
    div {
        //For each of the links we want to set up a click event
        div {
            className = StandardizedStyles.PageTitleHeaderLinkContainer
            props.linkCalls.forEach { (key, link) ->
                +"<"
                div {
                    className = StandardizedStyles.PageTitleHeaderLinks
                    onClick = { event ->
                        link.onClick.invoke(event)
                    }
                    +key
                }
            }
        }
    }
}