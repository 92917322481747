package com.fireavert.reports_page.interface_adaptors

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.reports_page.frameworks.EventTypes
import com.fireavert.reports_page.logic.ReportsPageScreen
import com.fireavert.reports_page.logic.models.HighRiskTenantModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ReportsPageViewModel : ViewModel(), ReportsPageScreen {
    val scope = viewModelScope
    private val _viewState = MutableStateFlow(ReportsPageViewState())
    val viewState = _viewState.asStateFlow()
    override var propertyMap: Map<String, Int?>
        get() = viewState.value.propertyMap
        set(value) {_viewState.value = viewState.value.copy(propertyMap = value)}
    override var allTimeData: Map<String, Int>
        get() = viewState.value.allTimeData
        set(value) { _viewState.value = viewState.value.copy(allTimeData = value)}
    override var propertyId: Int?
        get() = viewState.value.propertyId
        set(value) {_viewState.value = viewState.value.copy(propertyId = value)}
    override var loading: Boolean
        get() = viewState.value.loading
        set(value) {_viewState.value = viewState.value.copy(loading = value)}
    override var loadingHighRiskTenants: Boolean
        get() = viewState.value.loadingHighRiskTenants
        set(value) {_viewState.value = viewState.value.copy(loadingHighRiskTenants = value)}
    override var loadingYearlyEvents: Boolean
        get() = viewState.value.loadingYearlyEvents
        set(value) {_viewState.value = viewState.value.copy(loadingYearlyEvents = value)}
    override var loadingAllTimeEvents: Boolean
        get() = viewState.value.loadingAllTimeEvents
        set(value) {_viewState.value = viewState.value.copy(loadingAllTimeEvents = value)}
    override var loadingDeviceToEventRatio: Boolean
        get() = viewState.value.loadingDeviceToEventRatio
        set(value) {_viewState.value = viewState.value.copy(loadingDeviceToEventRatio = value)}
    override var thisYearSmokeEvents: Array<Int>
        get() = viewState.value.thisYearSmokeEvents
        set(value) {_viewState.value = viewState.value.copy(thisYearSmokeEvents = value)}
    override var thisYearTamperEvents: Array<Int>
        get() = viewState.value.thisYearTamperEvents
        set(value) {_viewState.value = viewState.value.copy(thisYearTamperEvents = value)}
    override var thisYearLeakEvents: Array<Int>
        get() = viewState.value.thisYearLeakEvents
        set(value) {_viewState.value = viewState.value.copy(thisYearLeakEvents = value)}
    override var thisYearStoveShutoffEvents: Array<Int>
        get() = viewState.value.thisYearStoveShutoffEvents
        set(value) {_viewState.value = viewState.value.copy(thisYearStoveShutoffEvents = value)}
    override var thisYearOfflineEvents: Array<Int>
        get() = viewState.value.thisYearOfflineEvents
        set(value) {_viewState.value = viewState.value.copy(thisYearOfflineEvents = value)}
    override var allTimeDeviceCount: Array<Int>
        get() = viewState.value.allTimeDeviceCount
        set(value) {_viewState.value = viewState.value.copy(allTimeDeviceCount = value)}
    override var yearlyDeviceCount: Array<Int>
        get() = viewState.value.yearlyDeviceCount
        set(value) {_viewState.value = viewState.value.copy(yearlyDeviceCount = value)}
    override var eventCount: Int
        get() = viewState.value.eventCount
        set(value) {_viewState.value = viewState.value.copy(eventCount = value)}
    override var highRiskTenants: List<HighRiskTenantModel>
        get() = viewState.value.highRiskTenants
        set(value) {_viewState.value = viewState.value.copy(highRiskTenants = value)}
    override var allTimeAverageEventValue: Double
        get() = viewState.value.allTimeAverageEventValue
        set(value) {_viewState.value = viewState.value.copy(allTimeAverageEventValue = value)}
    override var averageEventsPerMonth: Double
        get() = viewState.value.averageEventsPerMonth
        set(value) {_viewState.value = viewState.value.copy(averageEventsPerMonth = value)}
    override var propertyAverageComparison: Pair<Double, Boolean>
        get() = viewState.value.propertyAverageComparison
        set(value) {_viewState.value = viewState.value.copy(propertyAverageComparison = value)}
    override var yearStartedUsingFireAvert: Int
        get() = viewState.value.yearStartedUsingFireAvert
        set(value) {_viewState.value = viewState.value.copy(yearStartedUsingFireAvert = value)}
    override var lastFiveYearClaims: List<FireClaimData>
        get() = viewState.value.lastFiveYearClaims
        set(value) {_viewState.value = viewState.value.copy(lastFiveYearClaims = value)}
    override var exportError: String?
        get() = viewState.value.exportError
        set(value) { _viewState.value = viewState.value.copy(exportError = value)}
    override var deviceToEventRatio: Array<Double>
        get() = viewState.value.deviceToEventRatio
        set(value) { _viewState.value = viewState.value.copy(deviceToEventRatio = value)
        }
    override var deviceToOfflineEventRatio: Array<Double>
        get() = viewState.value.deviceToOfflineEventRatio
        set(value) { _viewState.value = viewState.value.copy(deviceToOfflineEventRatio = value)
        }
    override var firesAvertedCount: Int
        get() = viewState.value.firesAvertedCount
        set(value) {_viewState.value = viewState.value.copy(firesAvertedCount = value)}
    override var availableDeviceTypes: List<EventTypes>
        get() = viewState.value.availableDeviceTypes
        set(value) {_viewState.value = viewState.value.copy(availableDeviceTypes = value)}

}