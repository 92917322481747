package com.fireavert.components.administration

import com.fireavert.styles.AdministrationPageStyle
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.dom.Link

external interface AdminUseBoxesProps : Props {
    var boxTitle: String
    var boxValue: Int
    var link: String
}

val AdminUserBoxes = FC<AdminUseBoxesProps> { props ->

    Link {
        to = props.link
        div {
            className = AdministrationPageStyle.admimUserBoxes
            div {
                className = AdministrationPageStyle.adminUserBoxTitle
                +props.boxTitle
            }
            div {
                className = AdministrationPageStyle.adminUserBoxValue
                +props.boxValue.toString()
            }
        }
    }
}