package com.fireavert.components.dashboard

import com.fireavert.actions.ActionCard
import com.fireavert.actions.ActionCardTableData
import com.fireavert.components.active_events.ActiveEventsV2
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.datepicker.DashboardDatePicker
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.dashboard.adapters.DashboardController
import com.fireavert.dashboard.adapters.DashboardViewModel
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.EventLinkedAction
import com.fireavert.logging.Logger
import com.fireavert.styles.DashboardStylesV2
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.utilities.getKoinInstance
import com.fireavert.utilities.toDate
import com.fireavert.utilities.toLocalDate
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.system.useMediaQuery
import react.FC
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.window.WindowTarget
import kotlin.js.Date
import kotlin.time.Duration.Companion.days

val DashboardV2 = FC {
    var startDate: Date? by useState(Date())
    var endDate: Date? by useState(startDate)
    var propertyId: Int? by useState(null)
    var propertyMap: Map<String, Int?> by useState(mapOf("All" to null))
    var isLoading: Boolean by useState(false)
    var smokeEvents: List<DeviceEvent> by useState(emptyList())
    var waterEvents: List<DeviceEvent> by useState(emptyList())
    var tamperEvents: List<DeviceEvent> by useState(emptyList())
    var offlineEvents: List<DeviceEvent> by useState(emptyList())
    var stoveShutoffActions: List<EventLinkedAction> by useState(emptyList())
    var textAlertActions: List<EventLinkedAction> by useState(emptyList())
    var stoveResetActions: List<EventLinkedAction> by useState(emptyList())
    var listDropDownTime: Long by useState(Clock.System.now().minus(1, DateTimeUnit.DAY, TimeZone.UTC).epochSeconds)
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    var reloadJob: Job? = null

    val controller = getKoinInstance<DashboardController>()
    val viewModel = getKoinInstance<DashboardViewModel>()
    val logger = getKoinInstance<Logger>()

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.isLoading
            startDate = viewState.startDate?.toDate()
            endDate = viewState.endDate?.toDate()
            propertyId = viewState.propertyId
            propertyMap = viewState.propertyMap
            smokeEvents = viewState.smokeEvents
            waterEvents = viewState.waterEvents
            tamperEvents = viewState.tamperEvents
            offlineEvents = viewState.offlineEvents
            stoveShutoffActions = viewState.shutoffActions
            textAlertActions = viewState.textAlertActions
            stoveResetActions = viewState.resetActions
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            val start = startDate?.toLocalDate() ?: return@launch
            val end = endDate?.toLocalDate() ?: return@launch
            controller.onLoad(start, end, propertyId)
        }

    }

    fun updateData(newStartDate: Date?, newEndDate: Date?, newPropertyId: Int?) {
        reloadJob?.cancel()
        reloadJob = viewModel.scope.launch {
            controller.updateData(
                newStartDate?.toLocalDate(),
                newEndDate?.toLocalDate(),
                newPropertyId
            )
        }
    }

    LoggedIn {}
    div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Dashboard
        }
        div {
            className = PageStylesV2.mainContainer
            TopNavBarV2 { activeNavOption = NavOption.Dashboard }
            if (!isMobile) {
                div {
                    className = HelpStyles.feedbackButton
                    a {
                        href = "https://forms.gle/jTiz351BbcGXGZ7TA"
                        target = WindowTarget._blank
                        Button {
                            className = PageStylesV2.genericButton
                            color = ButtonColor.primary
                            variant = ButtonVariant.contained
                            +"Send Feedback"
                        }
                    }
                }
            }
            div {
                className = PageStylesV2.mainContainerContent
                if (isMobile) {
                    div {
                        className = DashboardStylesV2.mobileHeaderSpace
                        div {
                            className = DashboardStylesV2.mobileHeaderTitle
                            +"Dashboard"
                        }
                        DashboardDropdown {
                            labelText = "Time"
                            optionMap = mapOf(
                                "Last 24 Hours" to 1,
                                "Last 7 Days" to 7,
                                "Last 30 Days" to 30,
                                "Last 90 Days" to 92,
                                "Last 365 Days" to 365,
                            )
                            mobileVersion = isMobile
                            onChange = { selectedTime ->
                                val now = Clock.System.now()
                                val duration = selectedTime?.days ?: 1.days
                                val startInstant = now - duration

                                val finalDate = Date(startInstant.toEpochMilliseconds())
                                val endDateSelector = Date(now.toEpochMilliseconds())

                                listDropDownTime = startInstant.epochSeconds
                                updateData(finalDate, endDateSelector, propertyId)
                            }
                        }
                    }
                } else {
                    div {
                        ariaLabel = "Dropdown Container"
                        className = DashboardStylesV2.dropdownContainer
                        DashboardDropdown {
                            labelText = "Property"
                            optionMap = propertyMap
                            onChange = {
                                updateData(startDate, endDate, it)
                            }
                        }
                        DashboardDropdown {
                            labelText = "Time"
                            optionMap = mapOf(
                                "Last 24 Hours" to 1,
                                "Last 7 Days" to 7,
                                "Last 30 Days" to 30,
                                "This Quarter" to 92,
                                "This Year" to 365,
                            )
                            mobileVersion = isMobile

                            onChange = { selectedTime ->
                                val now = Clock.System.now()
                                val duration = selectedTime?.days ?: 1.days
                                val startInstant = now - duration

                                val finalDate = Date(startInstant.toEpochMilliseconds())
                                val endDateSelector = Date(now.toEpochMilliseconds())

                                listDropDownTime = startInstant.epochSeconds
                                updateData(finalDate, endDateSelector, propertyId)
                            }
                        }
                        DashboardDatePicker {
                            onChange = { newStartDate, newEndDate ->
                                updateData(newStartDate, newEndDate, propertyId)
                            }
                            this.startDate = startDate
                            this.endDate = endDate
                        }
                    }
                }
                div {
                    ariaLabel = "Event Card Container"
                    className = DashboardStylesV2.eventCardContainer
                    EventCard {
                        imageSrc = "/static/event_card_smoke.svg"
                        text = "Smoke Events"
                        number = smokeEvents.size
                        showLink = true
                        linkHref = "https://share.hsforms.com/1y3k-y7XnQROGK3w4JVxRIQ2za1i"
                        linkText = "Replace A Unit"
                        isMobileVersion = isMobile
                        isToiletFlowMonitor = false
                    }
                    EventCard {
                        imageSrc = "/static/event_card_leak.svg"
                        text = "Water Events"
                        number = waterEvents.size
                        showLink = true
                        linkHref = "https://share.hsforms.com/1y3k-y7XnQROGK3w4JVxRIQ2za1i"
                        linkText = "Replace A Unit"
                        isMobileVersion = isMobile
                        isToiletFlowMonitor = false
                    }
                    EventCard {
                        imageSrc = "/static/event_card_tamper.svg"
                        text = "Tamper Events"
                        number = tamperEvents.size
                        showLink = true
                        linkHref = "https://share.hsforms.com/1y3k-y7XnQROGK3w4JVxRIQ2za1i"
                        linkText = "Replace A Unit"
                        isMobileVersion = isMobile
                        isToiletFlowMonitor = false
                    }
                    EventCard {
                        imageSrc = "/static/toilet_icon.svg"
                        text = "Toilet Flow Monitor"
                        number = 0
                        showLink = true
                        linkHref = "https://forms.gle/XZgX7rrDjEEAvShN9"
                        linkText = "Interested? Join the waitlist"
                        isMobileVersion = isMobile
                        isToiletFlowMonitor = true
                    }
                }
                ActiveEventsV2 {
                    this.propertyId = propertyId
                    isMobileVersion = isMobile
                    eventsBasedOnTime = listDropDownTime
                }
                div {
                    className = DashboardStylesV2.automatedActionsTakenTitle
                    +"Automated Actions Taken"
                }
                div {
                    ariaLabel = "Action Card Container"
                    className = DashboardStylesV2.actionCardContainer

                    val timeZoneToShow = TimeZone.of("America/Denver")

                    val shortStoveShutoff = stoveShutoffActions.sortedBy { it.created }.reversed().take(100)
                        .map { ActionCardTableData.fromEventLinkedAction(it, timeZoneToShow) }
                    ActionCard {
                        imgSrc = "/static/action_card_shutoff_icon.svg"
                        text = "Stove Shutoff"
                        showLink = false
                        number = stoveShutoffActions.size
                        backgroundColor = Color("#D13238")
                        actionCardTableData = shortStoveShutoff
                        isMobileVersion = isMobile
                    }
                    val shortTextAlerts = textAlertActions.sortedBy { it.created }.reversed().take(100)
                        .map { ActionCardTableData.fromEventLinkedAction(it, timeZoneToShow) }
                    ActionCard {
                        imgSrc = "/static/action_card_text_icon.svg"
                        text = "Text Alerts"
                        showLink = true
                        linkHref = "http://www.fireavert.com"
                        linkText = "CUSTOMIZE ALERTS"
                        number = textAlertActions.size
                        backgroundColor = Color("#00AEEF")
                        actionCardTableData = shortTextAlerts
                        isMobileVersion = isMobile
                    }
                    val shortStoveResets = stoveResetActions.sortedBy { it.created }.reversed().take(100)
                        .map { ActionCardTableData.fromEventLinkedAction(it, timeZoneToShow) }
                    ActionCard {
                        imgSrc = "/static/action_card_reset_icon.svg"
                        text = "Stove Reset"
                        showLink = false
                        number = stoveResetActions.size
                        backgroundColor = Color("#00A651")
                        actionCardTableData = shortStoveResets
                        isMobileVersion = isMobile

                    }
                }
                // TODO - Add this back in when we have the ability to customize alerts
//                if (isMobile) {
//                    div {
//                        className = DashboardStylesV2.mobileCustomizeButtonWrapper
//                        div {
//                            className = DashboardStylesV2.mobileCustomizeButton
//                            +"Customize Alerts"
//                            img {
//                                src = "/static/bell_black.svg"
//                            }
//                        }
//                    }
//                }
            }
        }
    }
    div {}
}
