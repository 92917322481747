package com.fireavert.properties.presentation.property_portfolio

import com.fireavert.common.TableColumn
import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.property_portfolio.PropertyPortfolio

class PropertyPortfolioController(
    private val propertyPortfolio: PropertyPortfolio,
    private val propertyNavigator: PropertyNavigator
) {
    fun clickedAddNewProperty() {
        propertyNavigator.navigate(PropertyNavigator.Destination.AddNewProperty)
    }

    fun onLoad() = propertyPortfolio.didLoad()
    fun sortPropertiesByColumnMap(columns: Array<TableColumn>, properties: Map<String, List<Property>>, searchValue: String): Map<String, List<Property>> =
        propertyPortfolio.searchAndsortPropertiesByColumnMap(columns, properties, searchValue)
    fun sortPropertiesByColumn(columns: Array<TableColumn>, properties: Array<Property>, searchValue: String): Array<Property> =
        propertyPortfolio.searchAndsortPropertiesByColumn(columns, properties, searchValue)
}