package com.fireavert.reports_page.logic

import com.fireavert.common.Try
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.*
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventType.DeviceTriggered
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.reports_page.frameworks.EventTypes
import com.fireavert.reports_page.logic.models.responses.HighRiskTenantsResponse
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.math.round


class ReportsPagesSectionInteractor(
    private val screen: ReportsPageScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val reportsPageRepository: ReportsPageRepository,
    private val logger: Logger
) {

    companion object {
        private val months = arrayOf(
            "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        )
    }

    suspend fun onLoad(propertyId: Int?, eventTypeValue: String, yearToGet: Int) {
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingDeviceToEventRatio = true
        val properties = when(val maybe = propertyRepository.getProperties()) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {

                maybe.value
            }
        }

        screen.propertyMap = mapOf("None" to -1, "All" to null) + properties.sortedBy { it.name }.associate { it.name to it.id }
        if (propertyId == -1) {
            screen.propertyId = -1
            screen.loading = false
            screen.loadingYearlyEvents = false
            screen.loadingAllTimeEvents = false
            return
        }

        updateData(propertyId, eventTypeValue)
        loadHighRiskTenantData(propertyId, listOf(EventTypes.SMOKE.toString(), EventTypes.TAMPER.toString(), EventTypes.LEAK.toString(), EventTypes.SHUTOFF.toString()))
        loadYearlyEventTrends(propertyId, yearToGet)
    }

    suspend fun updateData(propertyId: Int?, eventTypeValue: String) {
        screen.propertyId = propertyId
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingHighRiskTenants = true

        val eventsOverTimeCount = when (val maybe = reportsPageRepository.getPropertyEventsOverTime(propertyId)) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        val firesAvertedCount = when (val maybe = reportsPageRepository.getFiresAvertedCount(propertyId)) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        val sensorTypes = when (val maybe = propertyRepository.getSensorTypesForProperty(propertyId)) {
            is Try.Error -> {
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }
        screen.availableDeviceTypes = sensorTypes

        screen.firesAvertedCount = firesAvertedCount
        screen.yearStartedUsingFireAvert = eventsOverTimeCount.yearStarted
        screen.lastFiveYearClaims = eventsOverTimeCount.fireClaimData
        loadEventsAndActions(propertyId, eventTypeValue)
        screen.loading = false
    }

    suspend fun loadYearlyEventTrends(propertyId: Int?, yearToGet: Int, updateYearlyDeviceCount: Boolean = true) {
        screen.loadingYearlyEvents = true
        val yearlyEventsData =
            when (val maybe = reportsPageRepository.getYearlyEvents(propertyId, yearToGet)) {
                is Try.Error -> {
                    screen.loadingYearlyEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }

        screen.thisYearSmokeEvents = months.map { month -> yearlyEventsData.thisYearSmokeEvents[month] ?:0 }.toTypedArray()
        screen.thisYearTamperEvents = months.map { month -> yearlyEventsData.thisYearTamperEvents[month] ?:0 }.toTypedArray()
        screen.thisYearLeakEvents = months.map { month -> yearlyEventsData.thisYearLeakEvents[month] ?:0 }.toTypedArray()
        screen.thisYearStoveShutoffEvents = months.map { month -> yearlyEventsData.thisYearStoveShutoffEvents[month] ?:0 }.toTypedArray()
        screen.thisYearOfflineEvents = months.map { month -> yearlyEventsData.thisYearOfflineEvents[month] ?:0 }.toTypedArray()
        if (updateYearlyDeviceCount) {
            screen.yearlyDeviceCount = yearlyEventsData.yearlyDeviceCount
        }


        screen.loadingYearlyEvents = false
    }

    suspend fun loadEventsAndActions(propertyId: Int?, eventTypeValue: String, loadRatioGraph: Boolean = true) {
        screen.loadingAllTimeEvents = true
        val eventsAndActions =
            when (val maybe = reportsPageRepository.getEventsAndActions(propertyId, eventTypeValue)) {
                is Try.Error -> {
                    screen.loadingAllTimeEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }


        screen.allTimeData = eventsAndActions.eventIncidentToMonthCount
        screen.eventCount = eventsAndActions.eventSize
        screen.allTimeDeviceCount = eventsAndActions.deviceCount
        screen.loadingAllTimeEvents = false
        if (loadRatioGraph) {
            loadDeviceToEventRatio(propertyId, eventsAndActions.deviceCount, eventTypeValue)
        }

    }

    suspend fun loadDeviceToEventRatio(propertyId: Int?, deviceCount: Array<Int>, eventDeviceType: String){
        screen.loadingDeviceToEventRatio = true
        val deviceToEventResult = when (val maybe = reportsPageRepository.getDeviceToEventRatio(propertyId, eventDeviceType)) {
            is Try.Error -> {
                screen.deviceToEventRatio = Array(deviceCount.size) { 0.0 }
                screen.loadingDeviceToEventRatio = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }
        val offlineRatioCount = Array<Double>(deviceCount.size) { 0.0 }
        val ratioCount = Array<Double>(deviceCount.size) { 0.0 }
        if (deviceToEventResult.eventCount.size != deviceCount.size) {
            // Handle size mismatch
            for (i in deviceToEventResult.eventCount.indices) {

                val value =  (deviceToEventResult.eventCount[i].toDouble() / deviceCount[0]).toDouble()
                if (value.isNaN()) {
                    ratioCount[i] = 0.0
                } else {
                    ratioCount[i] = value
                }
                val offlineValue = (deviceToEventResult.offlineEventCount[i].toDouble() / deviceCount[0]).toDouble()
                if (offlineValue.isNaN()) {
                    offlineRatioCount[i] = 0.0
                } else {
                    offlineRatioCount[i] = offlineValue
                }
            }
        }
        else {
            for (i in deviceCount.indices) {
                if (deviceCount[i] == 0 || deviceToEventResult.eventCount[i] == 0 || deviceToEventResult.offlineEventCount[i] == 0) {
                    ratioCount[i] = 0.0
                } else {
                    val value =  (deviceToEventResult.eventCount[i].toDouble() / deviceCount[0]).toDouble()
                    if (value.isNaN()) {
                        ratioCount[i] = 0.0
                    } else {
                        ratioCount[i] = value
                    }
                    val offlineValue = (deviceToEventResult.offlineEventCount[i].toDouble() / deviceCount[0]).toDouble()
                    if (offlineValue.isNaN()) {
                        offlineRatioCount[i] = 0.0
                    } else {
                        offlineRatioCount[i] = offlineValue
                    }
                }
            }
        }

        screen.loadingDeviceToEventRatio = false
        screen.deviceToEventRatio = ratioCount
        screen.deviceToOfflineEventRatio = offlineRatioCount
    }

    suspend fun loadHighRiskTenantData(propertyId: Int?, eventTypeArray: List<String>) {
        screen.loadingHighRiskTenants = true
        val highRiskTenants = when (val maybe = reportsPageRepository.getHighRiskTenants(propertyId, eventTypeArray)) {
            is Try.Error -> {
                screen.loadingHighRiskTenants = false
                HighRiskTenantsResponse(emptyList(), 0.0)
            }
            is Try.Success -> {
                maybe.value
            }
        }

        val currentAllPropertyEventValue = when (val maybe = reportsPageRepository.getAllPropertiesAverageValue(eventTypeArray, propertyId ?: -1)) {
            is Try.Error -> {
                screen.loading = false
                screen.loadingHighRiskTenants = false
                0.0
            }
            is Try.Success -> {
                maybe.value
            }
        }


        screen.allTimeAverageEventValue = (round(currentAllPropertyEventValue * 100) / 100)
        screen.highRiskTenants = highRiskTenants.highRiskTenants
        screen.averageEventsPerMonth = (round(highRiskTenants.averageEventsPerMonth * 100) / 100)
        screen.loadingHighRiskTenants = false
    }

    suspend fun exportEventsHappeningNow(propertyId: Int?): String? {
        val csvContent: String
        if (propertyId == null) {
            val exportData = when (val maybe = reportsPageRepository.exportEventsHappeningNowAll()) {
                is Try.Error -> {
                    screen.exportError = "Error exporting events happening now"
                    return null
                }

                is Try.Success -> {
                    maybe.value
                }
            }

            csvContent = buildString {
                appendLine("Property Name, Unit Number, Device Type, Event Type, Time")
                exportData.propertiesWithEvents?.forEach { eventData ->
                    eventData.activeDeviceEvents?.forEach { unitData ->
                        appendLine(
                            "${eventData.property?.name}, ${unitData.unit}, ${unitData.deviceType}, ${convertEventTypeToString(unitData.eventType, unitData.deviceType)}, ${convertTimestampToReadableTime(unitData.timestamp)}"
                        )
                    }
                }
            }
            return csvContent
        }
        else {
            val exportData = when (val maybe = reportsPageRepository.exportEventsHappeningNow(propertyId)) {
                is Try.Error -> {
                    screen.exportError = "Error exporting events happening now"
                    return null
                }

                is Try.Success -> {
                    maybe.value
                }
            }

            csvContent = buildString {
                appendLine("Property Name, Unit Number, Device Type, Event Type, Time")
                exportData.propertyWithActiveEventsResponse.activeDeviceEvents?.forEach { unitData ->
                    appendLine(
                        "${exportData.propertyWithActiveEventsResponse.property?.name}, ${unitData.unit}, ${unitData.deviceType}, ${convertEventTypeToString(unitData.eventType, unitData.deviceType)}, ${convertTimestampToReadableTime(unitData.timestamp)}"
                    )
                }
            }
            return csvContent
        }

    }

    private fun convertTimestampToReadableTime(timestamp: Long?): String {
        if (timestamp == null) {
            return ""
        }
        val instant = Instant.fromEpochSeconds(timestamp)
        val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())
        return "${localDateTime.date} ${localDateTime.time}"
    }

    private fun convertEventTypeToString(eventType: EventType?, deviceType: DeviceType?): String {
        if (eventType == DeviceTriggered) {
            return when (deviceType) {
                FireAvert, FireAvertGas, FireAvertAppliance -> "Stove Shutoff"
                WaterSensor -> "Water Leak"
                FlowSensor -> "Flow"
                TamperSensor -> "Tamper Detected"
                null -> "Triggered"
            }
        }
        return "Device Triggered"
    }
}