package com.fireavert.properties.presentation.property_portfolio

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.property_portfolio.PropertyPortfolioScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class PropertyPortfolioViewModel : ViewModel(), PropertyPortfolioScreen {
    private val _viewState = MutableStateFlow(PropertyPortfolioViewState())
    val viewState = _viewState.asStateFlow()

    override fun setProperties(properties: List<Property>) {
        _viewState.value = _viewState.value.copy(properties = properties)
    }

    override fun setManagementCompaniesWithProperties(managementCompaniesWithProperties: Map<String, List<Property>>) {
        _viewState.value = _viewState.value.copy(managementCompaniesWithProperties = managementCompaniesWithProperties)
    }

    override fun setLoading(loading: Boolean) {
       _viewState.value = _viewState.value.copy(loading = loading)
    }

    override fun setShowCreateButton(show: Boolean) {
       _viewState.value = _viewState.value.copy(showCreateButton = show)
    }

    override fun setShowEditProperty(show: Boolean) {
       _viewState.value = _viewState.value.copy(showEditProperty = show)
    }

    override fun setShowDeleteProperty(show: Boolean) {
        _viewState.value = _viewState.value.copy(showDeleteProperty = show)
    }

    override val scope: CoroutineScope
        get() = viewModelScope

}