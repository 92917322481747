package com.fireavert.administration_page.main_page.logic

data class AdministrationPageViewState(
    val loading: Boolean = false,
    val error: String = "",
    val propertyList: Map<String, Int> = emptyMap(),
    val totalUserCount: Int = 0,
    val propertyAdminCount: Int = 0,
    val fireavertAdminCount: Int = 0,
    val maintenanceUserCount: Int = 0,
    val executiveUserCount: Int = 0,
    val regionalManagerCount: Int = 0,
    val tenantCount: Int = 0,
    val singleFamilyUserCount: Int = 0,
)
