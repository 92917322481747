package com.fireavert.properties.logic.edit_property

import com.fireavert.common.Try
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.PropertyNavigator.Destination.Reload
import com.fireavert.properties.logic.PropertyValidator
import com.fireavert.properties.logic.models.*
import com.fireavert.properties.logic.models.request.PropertyFireClaimRequest
import com.fireavert.properties.logic.models.request.PropertyModeChangeRequest
import io.ktor.client.plugins.*
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class ClientEditProperty(
    private val screen: EditPropertyScreen,
    private val propertyNavigator: PropertyNavigator,
    private val propertyRepository: ClientPropertyRepository,
    private val logger: Logger,
) : EditProperty {
    override fun cancel(propertyId: Int) {
        propertyNavigator.navigate(PropertyNavigator.Destination.Property(propertyId))
    }

    override fun propertyNameChanged(targetInputValue: String) {
        screen.setPropertyNameError(false)
        screen.setSaveError(null)
        screen.setPropertyName(targetInputValue)
    }

    override fun numberOfUnitsChanged(targetInputValue: String) {
        screen.setSaveError(null)

        when (val maybeConverted: Try<Int> = Try.fromCallable { targetInputValue.toInt() }) {
            is Try.Success -> {
                screen.setNumberOfUnits(maybeConverted.value)
                screen.setNumberOfUnitsError(false)
            }

            is Try.Error -> {
                screen.setNumberOfUnitsError(true)
            }
        }
    }

    override fun propertyClassChanged(propertyClass: PropertyClass) {
        screen.setSaveError(null)
        screen.setPropertyClass(propertyClass)
        screen.setPropertyClassError(false)
    }

    override fun addressChanged(targetInputValue: String) {
        screen.setSaveError(null)
        screen.setAddress(targetInputValue)
        screen.setAddressError(false)
    }

    override fun cityChanged(targetInputValue: String) {
        screen.setSaveError(null)
        screen.setCity(targetInputValue)
        screen.setCityError(false)
    }

    override fun stateChanged(state: String) {
        screen.setSaveError(null)
        screen.setState(state)
        screen.setStateError(false)
    }

    override fun zipChanged(targetInputValue: String) {
        screen.setSaveError(null)
        screen.setZip(targetInputValue)
        screen.setZipError(false)
    }

    override fun managementCompanyChanged(targetInputValue: String) {
        screen.setSaveError(null)
        screen.setManagementCompanyError(false)
        screen.setManagementCompany(targetInputValue)
    }

    override fun managementCompanyIdChanged(managementCompanyId: Int) {
        screen.setSaveError(null)
        screen.setManagementCompanyError(false)
        screen.setManagementCompanyId(managementCompanyId)
    }

    override fun timezoneIdChanged(targetInputValue: String) {
        screen.setSaveError(null)
        screen.setTimezoneId(targetInputValue)
        screen.setTimezoneIdError(false)
    }

    override suspend fun save(propertyId: Int) {
        screen.setErrorMessage("")
        if (PropertyValidator.validate(screen)) {
            val propertyAdmins = screen.getPropertyAdmins()
            val propertyAdminsToModify = propertyAdmins.filter { it.existsOnServer }
            val propertyAdminsToAdd = propertyAdmins.filter { !it.existsOnServer }

            propertyAdminsToAdd.forEach { propertyAdmin ->
                when (val maybeSuccess = propertyRepository.createPropertyAdmin(
                    propertyId = propertyId,
                    propertyAdmin
                )) {
                    is Try.Success -> {}
                    is Try.Error -> {
                        var errorMessages = mutableListOf<String>()
                        if (maybeSuccess.exception is ClientRequestException &&
                            maybeSuccess.exception.response.status.value == 409
                        ) {
                            propertyAdmin.emailError = true
                            screen.setPropertyAdminsError(true)
                            errorMessages += "Cannot add a duplicate Property Admin Email."
                        }
                        errorMessages += "Failed to save ${maybeSuccess.exception.message}"
                        logger.e("Failed to save ${maybeSuccess.exception.message}")
                        screen.setSaveError(maybeSuccess.exception)
                        screen.setErrorMessage(errorMessages.joinToString(", "))
                        return
                    }
                }
            }

            propertyAdminsToModify.forEach { propertyAdmin ->
                when (val maybeSuccess = propertyRepository.modifyPropertyAdmin(
                    propertyId = propertyId,
                    propertyAdminModel = propertyAdmin
                )) {
                    is Try.Success -> {}
                    is Try.Error -> {
                        logger.e("Failed to save ${maybeSuccess.exception.message}")
                        screen.setSaveError(maybeSuccess.exception)
                        screen.setErrorMessage("Failed to save ${maybeSuccess.exception.message}")
                        return
                    }
                }
            }

            val fireClaimList = mutableListOf<FireClaimData>()
            val currentYear = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
            fireClaimList.add(FireClaimData(propertyId, currentYear - 0, screen.getFireClaimsOneClaimCount(), screen.getFireClaimsOneCost()))
            fireClaimList.add(FireClaimData(propertyId, currentYear - 1, screen.getFireClaimsTwoClaimCount(), screen.getFireClaimsTwoCost()))
            fireClaimList.add(FireClaimData(propertyId, currentYear - 2, screen.getFireClaimsThreeClaimCount(), screen.getFireClaimsThreeCost()))
            fireClaimList.add(FireClaimData(propertyId, currentYear - 3, screen.getFireClaimsFourClaimCount(), screen.getFireClaimsFourCost()))
            fireClaimList.add(FireClaimData(propertyId, currentYear - 4, screen.getFireClaimsFiveClaimCount(), screen.getFireClaimsFiveCost()))

            val fireClaimRequest = PropertyFireClaimRequest(fireClaimList, propertyId)
            when (val maybeFireClaims = propertyRepository.modifyFireClaims(fireClaimRequest)) {
                is Try.Success -> {}
                is Try.Error -> {
                    screen.setSaveError(maybeFireClaims.exception)
                    screen.setErrorMessage("Failed to save to due Fire Claim error: ${maybeFireClaims.exception.message}")
                    return
                }
            }

            if (screen.getMode() == Mode.Install) {
                when (val maybeModeTracker = propertyRepository.updatePropertyMode(
                    PropertyModeChangeRequest(
                        propertyId = propertyId,
                        mode = Mode.Install,
                        //One week for install mode
                        duration = 7 * 24 * 60
                    )
                )) {
                    is Try.Success -> {}
                    is Try.Error -> {
                        screen.setSaveError(maybeModeTracker.exception)
                        screen.setErrorMessage("Failed to save to due Mode error: ${maybeModeTracker.exception.message}")
                        return
                    }
                }
            }

            val success = { propertyNavigator.navigate(PropertyNavigator.Destination.Property(propertyId)) }

            val property = Property(
                id = propertyId,
                name = screen.getPropertyName() ?: "",
                units = screen.getNumberOfUnits() ?: 0,
                address = screen.getAddress() ?: "",
                city = screen.getCity() ?: "",
                state = screen.getState() ?: "",
                zip = screen.getZip() ?: "",
                managementCompany = screen.getManagementCompany() ?: "",
                managementCompanyId = screen.getManagementCompanyId() ?: 0,
                subscriptionType = screen.getSubscriptionType() ?: SubscriptionType.Standard,
                mode = screen.getMode() ?: Mode.Active,
                propertyType = screen.getPropertyType(),
                timezoneId = screen.getTimezoneId() ?: "",
                propertyClass = screen.getPropertyClass() ?: PropertyClass.UNKNOWN,
                startDate = ""
            )
            when (val maybeProperty = propertyRepository.modifyProperty(
                property = property
            )) {
                is Try.Success -> {
                    success()
                }

                is Try.Error -> {
                    logger.e("Failed to save ${maybeProperty.exception.message}")
                    screen.setSaveError(maybeProperty.exception)
                    screen.setErrorMessage("Failed to save ${maybeProperty.exception.message}")
                }
            }
        }
    }

    override suspend fun onLoad(propertyId: Int) {
        screen.setIsLoading(true)
        when (val maybeProperty = propertyRepository.getPropertyByIdAsync(propertyId)) {
            is Try.Success -> {
                val managementCompanies = when (val maybe =
                    propertyRepository.getManagementCompanies()) {
                    is Try.Success -> maybe.value
                    is Try.Error -> {
                        emptyMap()
                    }
                }

                screen.setIsLoading(false)
                val property = maybeProperty.value
                screen.setAddress(property.address)
                screen.setAddressError(false)
                screen.setCity(property.city)
                screen.setCityError(false)
                screen.setPropertyName(property.name)
                screen.setPropertyNameError(false)
                screen.setManagementCompany(property.managementCompany)
                screen.setManagementCompanyError(false)
                screen.setManagementCompanyId(property.managementCompanyId)
                screen.setManagementCompanyList(managementCompanies)
                screen.setSubscriptionType(property.subscriptionType)
                screen.setSubscriptionTypeError(false)
                screen.setMode(property.mode)
                screen.setModeError(false)
                screen.setNumberOfUnits(property.units)
                screen.setNumberOfUnitsError(false)
                screen.setState(property.state)
                screen.setStateError(false)
                screen.setZip(property.zip)
                screen.setZipError(false)
                screen.setTimezoneId(property.timezoneId)
                screen.setTimezoneIdError(false)
                screen.setPropertyType(property.propertyType)
                screen.setPropertyClass(property.propertyClass)

                val propertyAdmins = when (val maybe =
                    propertyRepository.getPropertyAdmins(propertyId = propertyId)) {
                    is Try.Success -> maybe.value
                    is Try.Error -> throw maybe.exception
                }
                screen.setPropertyAdmins(propertyAdmins)

                val unlinkedPropertyAdmins = when (val maybe =
                    propertyRepository.getUnlinkedPropertyAdmins(propertyId = propertyId)) {
                    is Try.Success -> maybe.value
                    is Try.Error -> throw maybe.exception
                }
                screen.setUnlinkedPropertyAdmins(unlinkedPropertyAdmins)
                screen.setIsLoading(false)
            }

            is Try.Error -> {
                screen.setIsLoading(false)
                //TODO handle this error
            }
        }

        when (val maybeFireClaims = propertyRepository.getFireClaims(propertyId)) {
            is Try.Success -> {
                val fireClaims = maybeFireClaims.value
                if (fireClaims.isEmpty()) {
                    screen.setFireClaimsOneCost(0.0)
                    screen.setFireClaimsOneClaimCount(0)
                    screen.setFireClaimsTwoCost(0.0)
                    screen.setFireClaimsTwoClaimCount(0)
                    screen.setFireClaimsThreeCost(0.0)
                    screen.setFireClaimsThreeClaimCount(0)
                    screen.setFireClaimsFourCost(0.0)
                    screen.setFireClaimsFourClaimCount(0)
                    screen.setFireClaimsFiveCost(0.0)
                    screen.setFireClaimsFiveClaimCount(0)
                }
                else {
                    for (claim in fireClaims) {
                        when (claim.year) {
                            Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year - 0 -> {
                                screen.setFireClaimsOneClaimCount(claim.claimCount)
                                screen.setFireClaimsOneCost(claim.cost)
                            }

                            Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year - 1 -> {
                                screen.setFireClaimsTwoClaimCount(claim.claimCount)
                                screen.setFireClaimsTwoCost(claim.cost)
                            }

                            Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year - 2 -> {
                                screen.setFireClaimsThreeClaimCount(claim.claimCount)
                                screen.setFireClaimsThreeCost(claim.cost)
                            }

                            Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year - 3 -> {
                                screen.setFireClaimsFourClaimCount(claim.claimCount)
                                screen.setFireClaimsFourCost(claim.cost)
                            }

                            Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year - 4 -> {
                                screen.setFireClaimsFiveClaimCount(claim.claimCount)
                                screen.setFireClaimsFiveCost(claim.cost)
                            }
                        }
                    }
                }
                screen.setFireClaimError(false)
            }

            is Try.Error -> {
                throw maybeFireClaims.exception
            }
        }
        //Get all gateways associated with the property
        when (val maybeGateways = propertyRepository.getPropertyGateways(propertyId)) {
            is Try.Success -> {
                screen.setGateways(maybeGateways.value)
                screen.setGatewayError(false)
            }

            is Try.Error -> {
                throw maybeGateways.exception
            }
        }
    }

    override fun propertyAdminModelsChanged(propertyAdminModels: List<PropertyAdminModel>) {
        screen.setPropertyAdmins(propertyAdminModels)
    }

    override fun linkPropertyAdmin(propertyId: Int, propertyAdmin: PropertyAdminModel) {
        screen.scope.launch {
            screen.setIsLoading(true)
            val propertyAdminIdInt = propertyAdmin.id.toInt()
            val success = when (val maybe =
                propertyRepository.linkPropertyAdmin(propertyId, propertyAdminIdInt)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    logger.e("Failed to link property admin ${maybe.exception}")
                    screen.setIsLoading(false)
                    throw maybe.exception
                }
            }
            if (!success) {
                logger.e("Failed to link property admin.")
                screen.setIsLoading(false)
                return@launch
            }
            propertyNavigator.navigate(Reload)
        }
    }

    override fun subscriptionTypeChanged(subscriptionType: SubscriptionType) {
        screen.setSaveError(null)
        screen.setSubscriptionType(subscriptionType)
        screen.setSubscriptionTypeError(false)
    }

    override fun modeChanged(mode: Mode) {
        screen.setSaveError(null)
        screen.setMode(mode)
        screen.setModeError(false)
    }

    override fun resendPropertyAdminInvite(propertyAdmin: PropertyAdminModel) {
        screen.scope.launch {
            propertyRepository.resendPropertyAdminInvite(propertyAdmin.id)
        }
    }

    override fun propertyTypeChanged(propertyType: PropertyType) {
        screen.setSaveError(null)
        screen.setPropertyType(propertyType)
        screen.setPropertyTypeError(false)
    }

    override fun fireClaimsChangedOneClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsOneClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedOneCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsOneCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedTwoClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsTwoClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedTwoCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsTwoCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedThreeClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsThreeClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedThreeCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsThreeCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFourClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsFourClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFourCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsFourCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFiveClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsFiveClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFiveCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsFiveCost(cost)
        screen.setFireClaimError(false)
    }

    override fun gatewayChanged(gateways: List<GatewayModel>) {
        screen.setSaveError(null)
        screen.setGateways(gateways)
        screen.setGatewayError(false)
    }

    override fun removePropertyAdminModel(propertyId: Int, propertyAdminModel: PropertyAdminModel) {
        try {
            val numericId = propertyAdminModel.id.toInt()
            propertyNavigator.navigate(
                PropertyNavigator.Destination.RemovePropertyAdmin(
                    propertyId,
                    numericId
                )
            )
        } catch (e: Exception) {
            logger.e("Failed to navigate to RemovePropertyAdmin(${propertyAdminModel.id})")
        }
    }
}