package com.fireavert.common

import com.fireavert.common.TableColumn.SortOrder.*

data class TableColumn(
    val text: String,
    val sortOrder: SortOrder,
    val alignment : Alignment,
    val enableSort : Boolean = true,
) {
    sealed class Alignment {
        object Left: Alignment()
        object Right: Alignment()
        object Center: Alignment()
    }
    sealed class SortOrder {
        object HighToLow: SortOrder()
        object LowToHigh: SortOrder()
        object Neutral: SortOrder()
    }

    companion object {
        fun <T, R : Comparable<R>> sortList(
            reverse: Boolean,
            sortColumn: TableColumn,
            eventsToSort: List<T>,
            sortFunc: (T) -> R
        ): List<T> {
            return when (sortColumn.sortOrder) {
                HighToLow -> if (!reverse) eventsToSort.sortedByDescending(sortFunc) else eventsToSort.sortedBy(
                    sortFunc
                )
                LowToHigh -> if (!reverse) eventsToSort.sortedBy(sortFunc) else eventsToSort.sortedByDescending(
                    sortFunc
                )
                Neutral -> eventsToSort
            }
        }
        fun <K, V, R : Comparable<R>> sortMap(
            reverse: Boolean,
            sortColumn: TableColumn,
            eventsToSort: Map<K, V>,
            sortFunc: (Map.Entry<K, V>) -> R
        ): Map<K, V> {
            return when (sortColumn.sortOrder) {
                HighToLow -> if (!reverse) eventsToSort.entries.sortedByDescending(sortFunc)
                else eventsToSort.entries.sortedBy(sortFunc)
                LowToHigh -> if (!reverse) eventsToSort.entries.sortedBy(sortFunc)
                else eventsToSort.entries.sortedByDescending(sortFunc)
                Neutral -> eventsToSort.entries
            }.associate { it.key to it.value }
        }
    }
}
