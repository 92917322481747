package com.fireavert.administration_page.main_page.logic

class AdministrationPageController(
    private val interactor: AdministrationPageInteractor
) {
    suspend fun onLoad() = interactor.onLoad()
    fun sortPropertyRecordByColumn(
        propertyList: Map<String, Int>,
        searchValue: String
    ): Map<String, Int> = interactor.searchPropertyByNames(propertyList, searchValue)
}