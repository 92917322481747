package com.fireavert.components.pages

import com.fireavert.components.administration.AdministrationContent
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import react.FC
import react.dom.html.ReactHTML

val AdministrationPageV2 = FC {
    LoggedIn {}
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Administration
        }
        ReactHTML.div {
            className = PageStylesV2.mainContainer
            TopNavBarV2 { activeNavOption = NavOption.Administration }
            ReactHTML.div {
                className = PageStylesV2.mainContainerContent
                AdministrationContent {}
            }
        }
    }
}