package com.fireavert.router

import com.fireavert.components.error.ErrorPage
import com.fireavert.components.pages.AdministrationPageV2
import com.fireavert.components.pages.administration.AdminGatewayPage
import com.fireavert.components.pages.administration.AdminToolsPage
import com.fireavert.components.pages.administration.AdminUserPage
import com.fireavert.menu.RoutePaths
import js.objects.jso
import react.create
import react.router.RouteObject

val AdminRoutes = arrayOf<RouteObject>(
    /** Admin Page Routes **/
    jso {
        path = "${RoutePaths.administration}/users/:userFilter"
        element = AdminUserPage.create {
        }
    },
    jso {
        path = "${RoutePaths.administration}/tools"
        element = AdminToolsPage.create {
        }
    },
    jso {
        path = "${RoutePaths.administration}/gateways"
        element = AdminGatewayPage.create {
        }
    },
    jso {
        path = RoutePaths.administration
        element = AdministrationPageV2.create {
        }
        ErrorBoundary = ErrorPage
    },
)