package com.fireavert.components.common.shared

import com.fireavert.styles.StandardizedStyles
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import web.html.HTMLDivElement

external interface PageHeaderProps : Props {
    var pageTitle: String
    var deleteMode: Boolean
    var linkCalls: List<HeaderLink>
    var permissionNeeded: String
}

external interface HeaderLink {
    var text: String
    var href: String
    var onClick: (MouseEvent<HTMLDivElement, *>) -> Unit
    var isActive: Boolean
}

val PageHeader = FC<PageHeaderProps> { props ->
    div {
        div {
            className = StandardizedStyles.PageTitleHeader
            +props.pageTitle
        }
        //For each of the links we want to set up a click event
        div {
            className = StandardizedStyles.PageTitleHeaderLinkContainer
            props.linkCalls.forEachIndexed { index, link ->
                div {
                    className = StandardizedStyles.PageTitleHeaderLinks
                    onClick = { event ->
                        link.onClick.invoke(event)
                    }
                    +link.text
                }
                if (index < props.linkCalls.size - 1) {
                    div {
                        +"|"
                    }
                }
            }
        }
    }
}