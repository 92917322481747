package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import emotion.css.cx
import web.cssom.*


object NavStylesV2 {
    val sideNav = ClassName {
        width = 256.px
        minWidth = 256.px
        backgroundColor = Color("#262626")
        zIndex = integer(1000)
        transition = Transition(TransitionProperty.all, 0.3.s, TransitionTimingFunction.easeInOut)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            display = None.none
            width = 0.px
            transform = translatex((-256).px)
        }
    }

    val notificationBell = ClassName {
        marginTop = 9.px
        marginLeft = 10.px
    }

    val mobileLogo = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        height = 25.px
        marginTop = Auto.auto
        marginBottom = Auto.auto
    }

    val logo = ClassName {
        marginLeft = 26.px
        marginTop = 8.px
    }
    val sideNavTop = ClassName {
        backgroundColor = NamedColor.white
    }
    val sideNavBottom = ClassName {
        marginTop = 40.px
    }
    val navItem = ClassName {
        color = NamedColor.white
        fontSize = 24.px
        marginTop = 15.px
        display = Display.flex
        padding = Padding(5.px, 0.px, 5.px, 24.px)
        cursor = Cursor.pointer
    }
    val navItemActive = cx(navItem,ClassName {
        backgroundColor = Color("#C73035")
    })
    val navItemIcon = ClassName {}
    val navItemText = ClassName {
        paddingLeft = 10.px
    }
    val topNav = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.right
        height = 49.px
        backgroundColor = NamedColor.white
        boxShadow =  BoxShadow(0.px, 4.px, 4.px, 0.px, rgb(0, 0, 0, 0.15))
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            backgroundColor = NamedColor.black
            justifyContent = JustifyContent.spaceBetween
        }
    }

    val userMenu = ClassName {
        display = Display.flex
        paddingRight = 10.px
        alignItems = AlignItems.center
        cursor = Cursor.pointer
    }

    val dashboardDropdown = ClassName {
        fontSize = 16.px
        height = 33.px
        backgroundImage = url("/static/dashboard_dropdown.png")
        border = None.none
        color = NamedColor.black
        paddingLeft = 5.px
        fontFamily = string("Open Sans")
        cursor = Cursor.pointer
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            borderRadius = 25.px
            border = Border(2.px, LineStyle.solid, Color("#000000"))
            fontWeight = integer(600)
            color = NamedColor.black
            maxWidth = 80.pct
        }
    }

    val dashboardDropdownDisabled = ClassName {
        fontSize = 16.px
        height = 33.px
        backgroundImage = url("/static/dashboard_dropdown.png")
        border = None.none
        color = Color("#939598")
        paddingLeft = 5.px
        fontFamily = string("Open Sans")
        cursor = Cursor.default
        pointerEvents = None.none
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            borderRadius = 25.px
            border = Border(2.px, LineStyle.solid, Color("#000000"))
            fontWeight = integer(600)
            color = NamedColor.black
        }
    }
}

object NavStyles {
    val sideNav = ClassName {
        width = 250.px
        display = Display.flex
        flexDirection = FlexDirection.column
    }
    val logo = ClassName {
        width = 147.px
        float = Float.left
    }

    val sideNavTop = ClassName {
        padding = Padding(13.px, 22.px, 13.px, 22.px)
        height = 60.px
        backgroundImage = linearGradient(180.deg, Color("#E71700"), Color("#FF6040"))
    }
    val sideNavBottom = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        height = 100.pct
        padding = 32.px
        backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
    }
    val navItem = ClassName {
        width = 100.pct
        height = 20.px
        color = Color("#A6B0CF")
        display = Display.flex
        alignItems = AlignItems.center
        cursor = Cursor.pointer
        marginBottom = 30.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            color = NamedColor.black
            padding = 10.px
        }
    }

    val navItemActive = ClassName {
        width = 100.pct
        height = 20.px
        color = NamedColor.white
        display = Display.flex
        alignItems = AlignItems.center
        cursor = Cursor.pointer
        marginBottom = 30.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 16.px
            fontWeight = FontWeight.bolder
            color = NamedColor.black
            padding = 10.px
        }
    }

    val navItemIcon = ClassName {
        width = 18.px
        height = 18.px
    }

    val navItemText = ClassName {
        marginLeft = 16.px
    }

    val navItemCounter = ClassName {
        position = Position.relative
//        width = 24.px
        height = 16.px
        marginLeft = Auto.auto
        paddingLeft = 5.px
        paddingRight = 5.px
        backgroundImage = None.none
        opacity = number(0.6)
        textAlign = TextAlign.center
        lineHeight = 16.px
        after {
            top = 0.px
            left = 0.px
            width = 100.pct
            height = 100.pct
            opacity = number(0.3)
            position = Position.absolute
            borderRadius = 5.px
            backgroundColor = Color("#A6B0CF")
        }
    }

    val navItemCounterActive = ClassName {
        position = Position.relative
//        width = 24.px
        paddingLeft = 5.px
        paddingRight = 5.px
        height = 16.px
        marginLeft = Auto.auto
        backgroundImage = linearGradient(180.deg, Color("#F54125"), Color("#FB764C"))
        borderRadius = 5.px
        opacity = number(1.0)
        textAlign = TextAlign.center
        lineHeight = 16.px
        after {
            top = 0.px
            left = 0.px
            width = 100.pct
            height = 100.pct
            opacity = number(0.3)
            position = Position.absolute
            borderRadius = 5.px
            backgroundColor = NamedColor.transparent
        }
    }

    val topNav = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        height = 60.px
        backgroundColor = NamedColor.white
        paddingLeft = 32.px
        paddingRight = 32.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            justifyContent = JustifyContent.spaceBetween
        }
    }

    val topNavMessage = ClassName {
        color = Color("#8B9BAD")
        fontSize = 16.px
        display = Display.flex
        alignItems = AlignItems.center
        fontWeight = integer(600)
    }

    val userMenu = ClassName {
        display = Display.flex
        cursor = Cursor.pointer
        alignItems = AlignItems.center
    }

    val userMenuName = ClassName {
        color = Color("#525F7F")
        fontSize = 14.px
        fontWeight = integer(600)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 12.px
            color = NamedColor.white
            borderRadius = 100.pct
            padding = 5.px
            border = Border(2.px, LineStyle.solid, Color("white"))
            maxWidth = 50.px
        }
    }

    val userMenuArrow = ClassName {
        marginLeft = 6.px
        width = 0.px
        height = 0.px
        borderTop = Border(6.px, LineStyle.solid, Color("#8B9BAD"))
        borderBottom = Border(0.px, LineStyle.solid, NamedColor.transparent)
        borderLeft = Border(4.px, LineStyle.solid, NamedColor.transparent)
        borderRight = Border(4.px, LineStyle.solid, NamedColor.transparent)
    }
}