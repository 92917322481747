package com.fireavert.administration_page.main_page.interface_adaptors

import com.fireavert.administration_page.main_page.logic.AdminPageRepositoryDataSource
import com.fireavert.administration_page.main_page.logic.AdministrationPageRepository
import com.fireavert.administration_page.main_page.logic.models.response.AdminGetUserCountResponse
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class AdministrationPageRepositoryImpl(
    private val datasource: AdminPageRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) : AdministrationPageRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getUserData(): Try<AdminGetUserCountResponse> = withContext(ioContext) {
        callWithRefresh { datasource.getUserData(preferences.token)}
            .map { it }
    }

    override suspend fun getPropertyList(): Try<Map<String, Int>> = withContext(ioContext) {
        callWithRefresh { datasource.getPropertyList(preferences.token)}
            .map { it.propertyList }
    }

}