package com.fireavert.components.auth

import com.fireavert.preferences.logic.Preferences
import com.fireavert.utilities.getKoinInstance
import react.FC
import react.PropsWithChildren
import react.ReactNode


external interface PermissionRequiredProps : PropsWithChildren {
    var permission: String
    var fallback: ReactNode?
}

val PermissionRequired = FC<PermissionRequiredProps> { props ->
    val viewModel = getKoinInstance<Preferences>()
    val hasPermission = viewModel.permissions.contains(props.permission)
    if (hasPermission) {
        +props.children
    } else {
        props.fallback?.let { +it }
    }
}