package com.fireavert.components.pages.administration

import com.fireavert.components.administration.DeviceIdMatchingSection
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.nav.NavOption
import com.fireavert.components.nav.SideNavV2
import com.fireavert.components.nav.TopNavBarV2
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import react.FC
import react.dom.html.ReactHTML

val AdminToolsPage = FC {
    LoggedIn {}
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Administration
        }
        PermissionRequired {
            permission = "CREATE_USERS"
            ReactHTML.div {
                className = PageStylesV2.mainContainer
                TopNavBarV2 { activeNavOption = NavOption.Administration }
                ReactHTML.div {
                    className = PageStylesV2.mainContainerContent
                    DeviceIdMatchingSection {}
                }
            }
        }
    }
}