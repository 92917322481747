package com.fireavert.administration_page.main_page

import com.fireavert.administration_page.main_page.logic.AdminPageRepositoryDataSource
import com.fireavert.administration_page.main_page.logic.models.response.AdminGetPropertiesListResponse
import com.fireavert.administration_page.main_page.logic.models.response.AdminGetUserCountResponse
import com.fireavert.common.Try
import com.fireavert.properties.logic.models.response.GetPropertiesResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminPageRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient
) : AdminPageRepositoryDataSource {
    override suspend fun getUserData(token: String): Try<AdminGetUserCountResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/admin/userCount")
            }
            if (response.status.isSuccess()) response.body() else {
                throw Exception("Failed to get user data")
            }
        }
    }

    override suspend fun getPropertyList(token: String): Try<AdminGetPropertiesListResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties")
            }
            if (response.status.isSuccess()) {
                val body = response.body<GetPropertiesResponse>()
                val propertyList = body.properties
                    ?.mapNotNull { prop ->
                        if (prop.name != null && prop.id != null) {
                            prop.name to prop.id
                        } else null
                    }
                    ?.toMap()
                    ?: emptyMap()
                AdminGetPropertiesListResponse(propertyList)
            } else {
                throw Exception("Failed to get property list")
            }
        }
    }

}
