package com.fireavert.components.administration

import com.fireavert.styles.AdminDiscoverPromoStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import web.window.WindowTarget


external interface AdminDiscoverPromoProps : Props {
    var imageSrc: String
    var title: String
    var description: String
    var link: String
}

val AdminDiscoverPromo = FC<AdminDiscoverPromoProps> { props ->
    div {
        className = AdminDiscoverPromoStyles.promoContainer

        div {
            className = AdminDiscoverPromoStyles.imageContainer

            img {
                className = AdminDiscoverPromoStyles.promoImage
                src = props.imageSrc
                alt = props.title
            }

            div {
                className = AdminDiscoverPromoStyles.overlay

                div {
                    className = AdminDiscoverPromoStyles.description
                    +props.description
                }

                h2 {
                    className = AdminDiscoverPromoStyles.title
                    +props.title
                }

                a {
                    href = "https://fireavert.com/pages/toilet-flow-sensor"
                    target = WindowTarget._blank
                    className = AdminDiscoverPromoStyles.learnMore
                    div {
                        +"Learn more >"
                    }
                }
            }
        }
    }
}