package com.fireavert.administration_page.main_page.interface_adaptors

import com.fireavert.administration_page.main_page.logic.AdministrationPageViewState
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AdministrationPageViewModel : AdministrationPageScreen, ViewModel() {
    private val _viewState = MutableStateFlow(AdministrationPageViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var loading: Boolean
        get() = _viewState.value.loading
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var error: String
        get() = _viewState.value.error
        set(value) {
            _viewState.value = _viewState.value.copy(error = value)
        }
    override var executiveUserCount: Int
        get() = _viewState.value.executiveUserCount
        set(value) {
            _viewState.value = _viewState.value.copy(executiveUserCount = value)
        }
    override var fireavertAdminCount: Int
        get() = _viewState.value.fireavertAdminCount
        set(value) {
            _viewState.value = _viewState.value.copy(fireavertAdminCount = value)
        }
    override var maintenanceUserCount: Int
        get() = _viewState.value.maintenanceUserCount
        set(value) {
            _viewState.value = _viewState.value.copy(maintenanceUserCount = value)
        }
    override var propertyAdminCount: Int
        get() = _viewState.value.propertyAdminCount
        set(value) {
            _viewState.value = _viewState.value.copy(propertyAdminCount = value)
        }

    override var propertyList: Map<String, Int>
        get() = _viewState.value.propertyList
        set(value) {
            _viewState.value = _viewState.value.copy(propertyList = value)
        }

    override var regionalManagerCount: Int
        get() = _viewState.value.regionalManagerCount
        set(value) {
            _viewState.value = _viewState.value.copy(regionalManagerCount = value)
        }

    override var tenantCount: Int
        get() = _viewState.value.tenantCount
        set(value) {
            _viewState.value = _viewState.value.copy(tenantCount = value)
        }
    override var totalUserCount: Int
        get() = _viewState.value.totalUserCount
        set(value) {
            _viewState.value = _viewState.value.copy(totalUserCount = value)
        }
}
