package com.fireavert.reports_page.interface_adaptors

import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.reports_page.frameworks.EventTypes
import com.fireavert.reports_page.logic.models.HighRiskTenantModel

data class ReportsPageViewState(
    val loading: Boolean = false,
    val loadingHighRiskTenants: Boolean = false,
    val loadingYearlyEvents: Boolean = false,
    val loadingAllTimeEvents: Boolean = false,
    val loadingDeviceToEventRatio: Boolean = false,
    var propertyId: Int? = null,
    var propertyMap: Map<String, Int?> = mapOf("None" to -1, "All" to null),
    val thisYearEventData: Map<String, Int> = emptyMap(),
    val thisYearSmokeEvents: Array<Int> = emptyArray(),
    val thisYearTamperEvents: Array<Int> = emptyArray(),
    val thisYearLeakEvents: Array<Int> = emptyArray(),
    val thisYearOfflineEvents: Array<Int> = emptyArray(),
    val thisYearStoveShutoffEvents: Array<Int> = emptyArray(),
    val allTimeDeviceCount: Array<Int> = emptyArray(),
    val yearlyDeviceCount: Array<Int> = emptyArray(),
    val deviceToEventRatio: Array<Double> = emptyArray(),
    val deviceToOfflineEventRatio: Array<Double> = emptyArray(),
    val allTimeData: Map<String, Int> = emptyMap(),
    val eventCount: Int = 0,

    val highRiskTenants: List<HighRiskTenantModel> = emptyList(),
    val averageEventsPerMonth: Double = 0.0,
    val propertyAverageComparison: Pair<Double, Boolean> = Pair(0.0, false),
    val allTimeAverageEventValue: Double = 0.0,
    val yearStartedUsingFireAvert: Int = 0,
    val lastFiveYearClaims: List<FireClaimData> = emptyList(),
    val exportError: String? = null,
    val firesAvertedCount: Int = 0,
    val availableDeviceTypes: List<EventTypes> = emptyList(),
    )