package com.fireavert.administration_page.main_page.logic.models.response

import kotlinx.serialization.Serializable

@Serializable
data class AdminGetUserCountResponse(
    val userCount: Int = 0,
    val propertyAdminCount: Int = 0,
    val fireavertAdminCount: Int = 0,
    val maintenanceUserCount: Int = 0,
    val executiveUserCount: Int = 0,
    val regionalManagerCount: Int = 0,
    val tenantCount: Int = 0,
    val singleFamilyUser: Int = 0,
)
