package com.fireavert.components.administration

import com.fireavert.administration_page.main_page.interface_adaptors.AdministrationPageViewModel
import com.fireavert.administration_page.main_page.logic.AdministrationPageController
import com.fireavert.components.auth.PermissionRequired
import com.fireavert.components.common.shared.HeaderLink
import com.fireavert.components.common.shared.PageHeader
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.AdministrationPageStyle
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.StandardizedStyles
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.TextField
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.ReactNode
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.dom.Link
import react.useEffectOnceWithCleanup
import react.useState
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLInputElement

val AdministrationContent = FC {
    val controller = getKoinInstance<AdministrationPageController>()
    val viewModel = getKoinInstance<AdministrationPageViewModel>()
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)

    var loading: Boolean by useState(false)
    var userCount: Int by useState(0)
    var fireavertAdminCount: Int by useState(0)
    var executiveUserCount: Int by useState(0)
    var regionalManagerCount: Int by useState(0)
    var propertyAdminCount: Int by useState(0)
    var maintenanceUserCount: Int by useState(0)
    var tenantCount: Int by useState(0)
    var singleFamilyUserCount: Int by useState(0)
    var propertyNameList: Map<String, Int> by useState(emptyMap())
    var isComponentMounted by useState(true)

    val (searchValue, setSearchValue) = useState("")

    useEffectOnceWithCleanup {
        isComponentMounted = true
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            propertyNameList = viewState.propertyList
            userCount = viewState.totalUserCount
            fireavertAdminCount = viewState.fireavertAdminCount
            executiveUserCount = viewState.executiveUserCount
            regionalManagerCount = viewState.regionalManagerCount
            propertyAdminCount = viewState.propertyAdminCount
            maintenanceUserCount = viewState.maintenanceUserCount
            tenantCount = viewState.tenantCount
            singleFamilyUserCount = viewState.singleFamilyUserCount
            loading = viewState.loading
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad()
        }

        onCleanup {
            isComponentMounted = false
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    PermissionRequired {
        permission = "CREATE_USERS"
        fallback = ReactNode("You do not have permission to view this page.")
        PageHeader {
            pageTitle = "Administration"
            deleteMode = false
            linkCalls = listOf(
                object : HeaderLink {
                    override var text = "Manage Permissions"
                    override var href = "/permissions"
                    override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    }
                    override var isActive = true
                },
                object : HeaderLink {
                    override var text = "Manage Parent Companies"
                    override var href = "/permissions"
                    override var onClick = { _: MouseEvent<HTMLDivElement, *> ->
                    }
                    override var isActive = true
                }
            )
        }

        div {
            className = StandardizedStyles.HeaderH2
            +"Users Within Purview"
        }

        div {
            className = AdministrationPageStyle.adminUseBoxContainer
            AdminUserBoxes {
                boxTitle = "Total Users"
                boxValue = userCount
                link = "${RoutePaths.administration}/users/all"
            }
            PermissionRequired {
                permission = "CREATE_PROPERTY"
                fallback = null
                AdminUserBoxes {
                    boxTitle = "FireAvert Employee"
                    boxValue = fireavertAdminCount
                    link = "${RoutePaths.administration}/users/fireavert"
                }
            }
            PermissionRequired {
                permission = "CREATE_PROPERTY"
                AdminUserBoxes {
                    boxTitle = "Executive Admin"
                    boxValue = executiveUserCount
                    link = "${RoutePaths.administration}/users/executive"
                }
            }

            PermissionRequired {
                permission = "CREATE_PROPERTY"
                AdminUserBoxes {
                    boxTitle = "Regional Manager"
                    boxValue = regionalManagerCount
                    link = "${RoutePaths.administration}/users/regional"
                }
            }
            PermissionRequired {
                permission = "CREATE_PROPERTY"
                AdminUserBoxes {
                    boxTitle = "Property Admin"
                    boxValue = propertyAdminCount
                    link = "${RoutePaths.administration}/users/property"
                }
            }
            PermissionRequired {
                permission = "CREATE_PROPERTY"
                AdminUserBoxes {
                    boxTitle = "Maintenance Users"
                    boxValue = maintenanceUserCount
                    link = "${RoutePaths.administration}/users/maintenance"
                }
            }
            PermissionRequired {
                permission = "CREATE_PROPERTY"
                AdminUserBoxes {
                    boxTitle = "Tenants"
                    boxValue = tenantCount
                    link = "${RoutePaths.administration}/users/tenant"
                }
            }
        }

        div {
            className = SearchbarStyles.titleWithSearchbarNew
            div {
                className = StandardizedStyles.HeaderH2
                +"Properties Within Purview"
            }

            div {
                className = SearchbarStyles.searchBarNew
                TextField {
                    sx {
                        height = 25.px
                    }
                    className = SearchbarStyles.searchBarInput(20)
                    placeholder = "Search..."
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }
        }

        div {
            div {
                className = TableStyles.newTableView
                div {
                    className = TableStyles.tableNew
                    val sortedProperties = controller.sortPropertyRecordByColumn(propertyNameList, searchValue)
                    sortedProperties.forEach { property ->
                        div {
                            className = TableStyles.tableRowAdminListNew
                            Link {
                                to = "${RoutePaths.properties}/${property.value}"
                                div {
                                    className = TableStyles.mobileTableRowWithArrow
                                    +property.key
                                    div {
                                        img {
                                            src = "/static/mobile_arrow_black.svg"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div {
            className = StandardizedStyles.HeaderH2
            +"Discover"
        }

        div {
            AdminDiscoverPromo {
                title = "The Toilet Flow Sensor"
                description = "New from FireAvert"
                imageSrc = "/static/images/toilet_flow_sensor_promo.png"
                link = "/toilet_flow_sensor"
            }
        }
        PermissionRequired {
            permission = "CREATE_PROPERTY"
            div {
                className = StandardizedStyles.HeaderH2
                +"Tools"
            }
            div {
                className = AdministrationPageStyle.adminToolsBoxContainer
                AdminToolBoxes {
                    boxTitle = "Device Connectivity"
                    boxIcon = "/static/admin_icons/device_connectivity.svg"
                }
                AdminToolBoxes {
                    boxTitle = "User Activity"
                    boxIcon = "/static/admin_icons/user_activity.svg"
                }
                AdminToolBoxes {
                    boxTitle = "Gateways"
                    boxIcon = "/static/admin_icons/gateways.svg"
                }
                AdminToolBoxes {
                    boxTitle = "Google Analytics"
                    boxIcon = "/static/admin_icons/google_analytics.svg"
                    link = "https://analytics.google.com/analytics/"
                }
                AdminToolBoxes {
                    boxTitle = "Super Admin Tools"
                    boxIcon = "/static/admin_icons/super_admin_tools.svg"
                    link = "${RoutePaths.administration}/tools"
                }
            }
        }
    }
}